// PRIMARY COLORS
$black: #000;
$white: #fff;
$brand-green-1: #00ca9f;
$brand-green-2: #009e82;
$brand-green-3: #146464;
$brand-green-4: #1f4855;
$brand-dark: #151833;
$gray-1: #f4f4f5;
$gray-2: #e7e7e9;
$gray-3: #cfd0d4;
$gray-4: #a0a1ad;
$gray-5: #717384;
$gray-6: #42434d;
$gray-7: #1c1d21;
$action-red: #ca3541;
$action-red-dark: #961535;
$action-gold: #e3bd36;
$action-gold-dark: #b17c0f;
$action-purple: #a24ab0;
$action-purple-dark: #563496;
$action-blue: #294bc3;
$action-blue-dark: #116789;
$pastel-yellow: #f2f2ee;
$pastel-yellow-dark: #ddddd2;
$pastel-red: #ede9e7;
$pastel-red-dark: #d8d0cd;
$pastel-green: #eaf2f1;
$pastel-green-dark: #d1dcda;

$primary: $brand-dark;
$secondary: $brand-green-3;
$secondary-dark: $brand-green-4;
$secondary-light: $brand-green-2;
$gray: $gray-1;
$gray-dark: $gray-2;
$gray-dark2: $gray-3;
$gray-darker: $gray-4;
$gray-darkest: $gray-5;
$blue-dark: $action-blue-dark;
$blue: $action-blue;
$purple-dark: $action-purple-dark;
$purple: $action-purple;
$red-dark: $action-red-dark;
$red: $action-red;
$gold-dark: $action-gold-dark;
$gold: $action-gold;
$gradient-green: linear-gradient(to right, $brand-green-1, $brand-dark);
$gradient-purple: linear-gradient(
    to right,
    $red -98%,
    $purple 159%,
    $secondary-light 399%
);
$gradient-blue: linear-gradient(
    to right,
    $red -299%,
    $purple -67%,
    $secondary-light 149%
);
$gradient-rainbow: linear-gradient(
    to right,
    $red,
    $purple 49%,
    $secondary-light
);

%green-gradient {
    background-image: $gradient-green;
}

%blue-gradient {
    background-image: $gradient-blue;
}

%purple-gradient {
    background-image: $gradient-purple;
}

%rainbow-gradient {
    background-image: $gradient-rainbow;
}

// COLORS
$charade: #2c2d3b;
$alabaster: #fafafa;
$deep-alabaster: #f8f8f8;
$teal: #1c8c85;
$secondary-bright: #cbebe6;
$gray2: #808080;
$gray3: #dddde2;
$gray4: $gray-2;
$alto: #d9d9d9;
$charcoal: #686b6f;
$teal-grey: #5f6362;
$scooter: #26b1d9;
$blue2: #252c60;
$blue3: #1e213d;
$blue-light: #47c8ed;
$turquoise: #45d2cc;
$light-sea-green: #21a69d;
$baby-blue: #219cbf;
$matisse: #1a7894;
$faux-cadet-grey: #2a2d55;
$darker-purple: #1e213d;
$purple-light: #5853a2;
$font-gray: #727484;
$purple-gray: #74758c;

// FONTS
$font-TWKLausanne: var(--font-twk-lausanne), ui-sans-serif, system-ui,
    -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto', 'Helvetica Neue',
    arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji', sans-serif;
$font-tiempos: var(--font-tiempos);

$calibre: var(--font-calibre-regular), -apple-system, blinkmacsystemfont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
$calibre-thin: var(--font-calibre-thin), -apple-system, blinkmacsystemfont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
$calibre-light: var(--font-calibre-light), -apple-system, blinkmacsystemfont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
$calibre-medium: var(--font-calibre-medium), -apple-system, blinkmacsystemfont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
$calibre-semibold: var(--font-calibre-semibold), -apple-system,
    blinkmacsystemfont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
$calibre-bold: var(--font-calibre-bold), -apple-system, blinkmacsystemfont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
$tiempos: var(--font-tiempos-headline-medium), serif;
$tiempos-light: var(--font-tiempos-headline-light), serif;
$tiempos-medium: var(--font-tiempos-medium), serif;
$tiempos-semibold: var(--font-tiempos-headline-semibold), serif;
$tiempos-bold: var(--font-tiempos-headline-bold), serif;
$circularair-light: var(--font-circular-air-light);
$circularair-book: var(--font-circular-air-book);

// BREAKPOINTS
$breakpoint-sml: 367px;
$breakpoint-mobile: 567px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$breakpoint-lg: 1200px;
$breakpoint-lg-blog: 1300px;
$breakpoint-xl: 1440px;

// NEW BREAKPOINTS
$breakpoint-lg-new: 1024px;
$breakpoint-xl-new: $breakpoint-lg;

$font-sizes: 128, 96, 72, 60, 56, 48, 40, 36, 32, 30, 28, 24, 22, 20, 18, 17, 16,
    14, 12;

// ANIMATIONS
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin-counter {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes fadeNewMessage {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeHeroCarouselInitial {
    0% {
        opacity: 0;
        transform: translate3d(0, 10%, 0);
    }

    8% {
        transform: translate3d(0, 0, 0);
    }

    40% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeHeroCarousel {
    0% {
        opacity: 0;
        transform: translate3d(0, 10%, 0);
    }

    8% {
        transform: translate3d(0, 0, 0);
    }

    40% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
