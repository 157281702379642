@import './variables';
@import './resets';
@import './common';

html {
    font-size: 16px;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
}

[data-theme='dark'] {
    background-color: white;

    iframe {
        color-scheme: light;
    }
}

body {
    color: $black;
    font-family: $calibre;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.3;

    &.navActive {
        overflow: hidden;
    }
}

.tailwind,
.viome3,
.viome3--section {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .heading,
    p,
    a,
    b,
    i,
    strong,
    em {
        font-family: $font-TWKLausanne;
        font-weight: 300;
        line-height: 1.5;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    h1,
    .heading-1 {
        font-size: 2.25rem; /* 36px */
    }

    h1,
    h2,
    .heading-1,
    .heading-2 {
        line-height: 1.3;
    }

    h2,
    h3,
    .heading-2,
    .heading-3 {
        font-size: 1.875rem; /* 30px */
    }

    h3,
    .heading-3 {
        font-size: 1.875rem; /* 30px */
    }

    h4,
    .heading-4 {
        font-size: 1.5rem; /* 24px */
    }

    h5,
    .heading-5 {
        font-size: 1.25rem; /* 20px */
    }

    h6,
    .heading-6 {
        font-size: 1rem; /* 16px */
    }

    p,
    a {
        font-size: 1rem; /* 16px */
    }

    a {
        text-decoration: underline;
        transition-duration: 0.1s;

        &:hover,
        &:focus,
        &:active {
            color: $brand-green-3;
        }
    }

    b,
    strong {
        font-weight: 600;
    }

    i,
    em {
        font-style: italic;
    }

    ol,
    ul {
        margin-left: 1.5625rem;
        text-align: left;
    }

    ol {
        list-style: auto;

        li {
            &::marker {
                font-family: $font-TWKLausanne;
                font-size: 1rem;
            }
        }
    }

    ul {
        li {
            line-height: 1.5;
            list-style-type: disc;

            &::marker {
                font-size: 1.25rem;
                line-height: 1;
            }

            ul {
                li {
                    list-style-type: circle;
                }
            }
        }
    }

    table {
        width: 100%;
        border: 1px solid $black;
        table-layout: auto;

        tr {
            border-bottom: 1px solid $black;
            text-align: left;

            &:last-of-type {
                border: 0;
            }
        }

        th {
            background: $gray-2;
        }

        th,
        td {
            padding: 1rem;
        }
    }

    img {
        transition-duration: 0.3s;
    }

    .viome3--section {
        position: relative;
        width: 100%;

        .row {
            .row__container {
                width: auto;
            }
        }
    }

    .wrapper {
        > .Default,
        > .Slim,
        > .Slimmer {
            > .row__container {
                width: calc(100% - 1.875rem);
            }
        }

        > .Wide,
        > .Default,
        > .Slim,
        > .Slimmer {
            > .row__container {
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    .container {
        max-width: none !important; /* Override for tailwind default container */

        > section {
            position: relative;
            width: calc(100% - 2rem);
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media only screen and (max-width: $breakpoint-tablet - 1) {
        .hideMobile {
            display: none !important;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .wrapper {
            > .Default,
            > .Slim,
            > .Slimmer {
                > .row__container {
                    width: calc(100% - 5rem);
                }
            }
        }

        .container {
            > section {
                width: calc(100% - 5rem);
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-lg-new - 1) {
        .hideTablet {
            display: none !important;
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        .hideDesktop {
            display: none !important;
        }

        h1,
        .heading-1 {
            font-size: 3rem; /* 48px */
        }

        h2,
        .heading-2 {
            font-size: 2.25rem; /* 36px */
        }

        h6,
        .heading-6 {
            font-size: 1.125rem; /* 18px */
        }

        .wrapper {
            > .Default,
            > .Slim,
            > .Slimmer {
                > .row__container {
                    width: min(1280px, calc(100% - 10rem));
                }
            }

            > .Slim {
                .row__container {
                    max-width: 1000px;
                }
            }

            > .Slimmer {
                .row__container {
                    max-width: 768px;
                }
            }

            > .Wide {
                .row__container {
                    max-width: 1440px;
                }
            }

            > .Full {
                .row__container {
                    max-width: none;
                }
            }
        }

        .container {
            > section {
                width: min(1280px, calc(100% - 10rem));

                &#references {
                    width: auto;
                    max-width: none;
                }
            }
        }

        &.blog {
            .container {
                > section {
                    max-width: 1280px;
                }
            }
        }
    }
}

.stickyBanner {
    ~ main {
        .stickyRow {
            top: 79px;
        }

        .banner {
            top: 80px;
        }
    }
}

main {
    &:not(.viome3) {
        &.products,
        &.topics,
        &.dna {
            .wrapper {
                overflow: visible;
                max-width: none;
            }
        }

        .wrapper {
            position: relative;
            max-width: 1440px;
            margin: auto;
            overflow-x: hidden;
        }

        .container {
            .about-product {
                max-width: none;

                .viome__section__wrapper {
                    max-width: none;
                }
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .heading,
    p {
        margin: 0;
        line-height: 1;
    }

    h1,
    h2,
    h3,
    h4,
    h6,
    .heading {
        font-family: $calibre-semibold;
        font-weight: 600;
    }

    h1,
    .heading-1 {
        font-size: 2.5rem; /* 40/16 */
    }

    h2,
    .heading-2 {
        font-size: 2.5rem; /* 40/16 */
    }

    h3,
    .heading-3 {
        font-size: 2.1875rem; /* 35/16 */
    }

    h4,
    .heading-4 {
        font-size: 1.875rem; /* 30/16 */
    }

    h5,
    .heading-5 {
        font-size: 1.375rem; /* 22/16 */
    }

    h6,
    .heading-6 {
        font-size: 1.75rem; /* 18/16 */
    }

    p {
        font-family: $calibre;
        font-size: 1.375rem; /* 22/16 */
        line-height: calc(28 / 22);
    }

    b {
        font-family: $calibre-semibold;
        font-weight: normal;
    }

    hr {
        height: 1px;
        border: 0;
        margin: 3.125rem 0;
        background-color: $gray-dark;
    }

    picture {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        h1 {
            font-size: 3rem; /* 48/16 */
        }

        h4 {
            font-size: 2.25rem; /* 36/16 */
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        h1 {
            font-size: 3.75rem; /* 60/16 */
        }

        h2 {
            font-size: 3.125rem; /* 50/16 */
        }
    }
}

.viome {
    .container {
        margin: auto;
    }

    &__section {
        max-width: 1440px;
        margin: auto;

        &::after {
            display: block;
            width: 100%;
            height: 1px;
            content: '';
            visibility: hidden;
        }

        .viome__section {
            padding: 0;
            margin: auto;
        }

        &__wrapper {
            width: 100%;
            padding: 0 1.875rem;

            &.full {
                padding: 0;
            }

            &.reverse {
                display: flex;
                flex-direction: column-reverse;

                h4 {
                    order: 1;
                }
            }

            &.marginTop {
                margin-top: 8.5rem;
            }

            &.marginBottom {
                margin-bottom: 8.5rem;
            }

            > div {
                width: 100%;
            }

            .viome__section__wrapper {
                padding: 0;
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                padding: 0 2.5rem;

                &.full {
                    padding: 0;
                }

                &.marginTop {
                    margin-top: 10.625rem;
                }

                &.marginBottom {
                    margin-bottom: 10.625rem;
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                max-width: 1440px;
                padding: 0;
                margin: auto;

                &.reverse {
                    flex-direction: row-reverse;
                    align-items: center;
                }
            }
        }
    }
}
